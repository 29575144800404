<template>
  <section class="invoice-preview-wrapper">
    <confirm-modal ref="confirmModal" />

    <b-row v-if="!loading" class="invoice-preview">
      <!-- Col: Left (Invoice Container) -->
      <b-col cols="12" xl="8" md="8">
        <b-card no-body class="invoice-preview-card">
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">
            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
            >
              <!-- Header: Left Content -->
              <div>
                <p class="font-weight-bold">
                  {{ T("Web.Generic.Invoices.Reciver", "Receiver") }}
                </p>
                <p class="card-text mb-25">{{ invoiceData.customer.name }}</p>
                <p class="card-text mb-25">
                  {{ invoiceData.customer.address }}
                </p>
                <p class="card-text mb-25">
                  {{ invoiceData.customer.zip }} {{ invoiceData.customer.city }}
                </p>
                <p class="card-text mb-25">{{ invoiceData.customer.cvr }}</p>
              </div>

              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-2">
                <h4 class="invoice-title">
                  {{ getDocumentTypeDisplayText(invoiceData.documentType) }}
                  <span class="invoice-number"
                    >#{{ invoiceData.invoiceNumber }}</span
                  >
                </h4>
                <div>
                  <p class="font-weight-bold">
                    {{ T("Web.Generic.Invoices.Sender", "Sender") }}
                  </p>
                  <p class="card-text mb-25">{{ invoiceData.supplier.name }}</p>
                  <p class="card-text mb-25">
                    {{ invoiceData.supplier.address }}
                  </p>
                  <p class="card-text mb-25">
                    {{ invoiceData.supplier.zip }}
                    {{ invoiceData.supplier.city }}
                  </p>
                  <p class="card-text">{{ invoiceData.supplier.cvr }}</p>
                  <br />
                  <div class="invoice-date-wrapper">
                    <p class="invoice-date-title">
                      {{ T("Web.Generic.Invoices.InvoiceDate", "Date") }}
                    </p>
                    <p class="invoice-date">
                      {{ format_date(invoiceData.issueDate) }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </b-card-body>

          <b-table-lite
            responsive
            :items="tableContent.items"
            :fields="tableContent.fields"
            primary-key="id"
          >
            <template #cell(date)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ format_date(data.item.date) }}
              </b-card-text>
            </template>
            <template #cell(text)="data">
              <b-card-text class="font-weight-bold mb-25">
                <span>{{ data.item.text }}</span>
                <br />
                <small>
                  {{ data.item.description }}
                  <br />
                  {{ data.item.note }}
                </small>
              </b-card-text>
            </template>
            <template #cell(amount)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ data.item.amount.toFixed(2) }}
              </b-card-text>
            </template>
            <template #cell(priceAmount)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ data.item.priceAmount.toFixed(2) }}
              </b-card-text>
            </template>
            <template #cell(priceTotal)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ data.item.priceTotal.toFixed(2) }}
              </b-card-text>
            </template>
          </b-table-lite>

          <!-- Spacer -->
          <hr class="invoice-spacing" />
          <!-- Note -->
          <b-card-body class="invoice-padding pt-0">
            <span class="font-weight-bold">
              {{ T("Web.Generic.Invoices.InvoiceNote", "Note") }}
            </span>
            <span>{{ invoiceData.note }}</span>
          </b-card-body>
        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col cols="12" md="4" xl="3" class="invoice-actions">
        <b-card>
          <!-- Button: DOwnload -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            @click="exportInvoice"
          >
            {{ T("Web.Generic.Download", "Download") }}
          </b-button>

          <!-- Button: Print -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            @click="printInvoice"
          >
            {{ T("Web.Generic.Print", "Print") }}
          </b-button>

          <!-- Button: Edit -->

          <b-button
            v-if="invoiceData.status === 'Confirmed'"
            variant="primary"
            block
            :to="{
              name: 'economics-reconciliation',
              params: { invoiceId: $route.params.id },
            }"
          >
            {{ T("Web.Generic.Invoices.Reconciliationed", "Reconciliationed") }}
          </b-button>
          <b-button
            v-if="invoiceData.status !== 'Confirmed'"
            variant="outline-danger"
            block
            @click.prevent="handleDeleteInvoice"
          >
            {{ T("Web.Generic.Delete", "Delete") }}
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BTableLite,
  BCardText,
  BButton,
  BLink,
  VBToggle,
} from "bootstrap-vue";
import Logo from "@core/layouts/components/Logo.vue";
import Ripple from "vue-ripple-directive";
import { mapActions, mapGetters } from "vuex";
import InvoicesService from "@/services/invoices.service";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";

export default {
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BLink,
    Logo,
    ConfirmModal,
  },
  created() {
    this.fetchInovice({
      id: this.$route.params.id,
      include: ["invoiceLines", "Reconciliation"],
    });
  },
  methods: {
    ...mapActions({
      fetchInovice: "economicsInvoices/fetchSingle",
      deleteInvoice: "economicsInvoices/delete",
    }),
    printInvoice() {
      window.print();
    },
    getDocumentTypeDisplayText(type) {
      return this.T(`Web.Generic.Invoices.DocumentTypes.${type}`)
    },
    async handleDeleteInvoice() {
      if (
        !(await this.$refs.confirmModal.confirm(
          this.T("Web.Generic.ConfirmDelete")
        ))
      )
        return;
      await this.deleteInvoice({ resourceId: this.$route.params.id });
      this.$router.push({ name: "economics.invoices.list" });
    },
    async exportInvoice() {
      const service = new InvoicesService();
      await service.getExcelExport(this.invoiceData.id);
    },
  },
  computed: {
    ...mapGetters({
      invoice: "economicsInvoices/byId",
      loading: "economicsInvoices/isLoading",
    }),
    tableContent() {
      return {
        fields: [
          {
            key: "lineId",
            label: this.T("Web.Generic.Invoices.LineId", "Line id"),
            sortable: true,
          },
          {
            key: "date",
            label: this.T("Web.Generic.Invoices.LineDate", "Date"),
            sortable: true,
          },
          {
            key: "sku",
            label: this.T("Web.Generic.Invoices.LineSKU", "Id"),
            sortable: true,
          },
          {
            key: "text",
            label: this.T("Web.Generic.Invoices.LineDescription", "Description"),
            sortable: false,
          },
          {
            key: "amount",
            label: this.T("Web.Generic.Invoices.LineAmount", "Amount"),
            sortable: false,
          },
          {
            key: "unit",
            label: this.T("Web.Generic.Invoices.LineUnit", "Unit"),
            sortable: false,
          },
          {
            key: "priceAmount",
            label: this.T("Web.Generic.Invoices.LineUnitPrice", "Unit price"),
            sortable: false,
          },
          {
            key: "priceTotal",
            label: this.T("Web.Generic.Invoices.LineTotal", "Total price"),
            sortable: false,
          },
        ],
        items: this.invoiceData.invoiceLines,
      };
    },
    invoiceData() {
      return this.invoice(this.$route.params.id);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
.reconciliationed-btn {
  &:hover:not(.disabled):not(:disabled) {
    background-color: #30cf84;
    color: #fff;
    border: 1px solid #2ba269c4 !important;
  }

  &.posted {
    background-color: #30cf84;
    border: 1px solid #2ba269c4 !important;
    color: #fff;
  }
}

@media print {
  // Global Styles
  body {
    background-color: transparent !important;
  }

  nav.header-navbar {
    display: none;
  }

  .main-menu {
    display: none;
  }

  .header-navbar-shadow {
    display: none !important;
  }

  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }

  footer.footer {
    display: none;
  }

  .card {
    background-color: transparent;
    box-shadow: none;
  }

  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
